import React from 'react';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';


export default function TemplatePersonalProgramProfile (props:any) {

  const handleViewMore = (itemCopy:any) => {
    window.scrollTo(0, 450);
    props.setProfileData(itemCopy);
    props.setCurrentSelectTab("detail")
  };

  return (
    <div className="personal-programbox" style={{backgroundColor: props.selectedDarkMode ? '#333': '#fffef4'}}>
      <div className="personal-program-title">&#183; Program &#183;</div>
      <div className="personal-program-subtitle">1부</div>
      <ul className="personal-program-cover">
        {
          props.programData1st.map((item:any, index:any)=>{

            const subPlayersCopy = JSON.parse(item.subPlayers)

            return (
              <li className="personal-program-list" key={index}>
                <div className="personal-program-many-titlerow">
                  <div className="personal-program-many-titlerow-left">
                    <h3>{item.playerPart}</h3>
                    <h1>{item.playerName}</h1>
                  </div>
                  <div className="personal-program-many-titlerow-right">
                    {
                      (item.lessonTeacher !== '' && item.lessonTeacher !== null && item.lessonTeacher !== 'null') &&
                      <p>지도교수 {item.lessonTeacher}</p>
                    }
                    {
                      (item.accompanist !== '' && item.accompanist !== null && item.accompanist !== 'null') &&
                      <p>반주자 {item.accompanist}</p>
                    }
                  </div>
                </div>
                <div className="personal-program-many-subPlayers-row">
                { 
                  ( subPlayersCopy.length > 0 && subPlayersCopy[0] !== '') &&
                    subPlayersCopy.map((item:any, index:any)=>(
                    <h3 key={index}>{item}</h3>
                  ))
                }
                </div>
                <div className="personal-program-many-contentrow">
                  <div className="personal-program-many-contentrow-top">
                    <div className="personal-program-composition-textrow">
                      <p className="personal-program-composition">{item.songComposer}</p>
                    </div>
                    <div className="personal-program-songname-box">
                      <p className="personal-program-songname">{item.songTitle}</p>
                      {
                        (item.songSubTitle !== '' && item.songSubTitle !== null) &&
                        <p className="personal-program-subsongname">{item.songSubTitle}</p>
                      }
                      {
                        (item.songFrom !== '' && item.songFrom !== null) &&
                        <p className="personal-program-subsongname">{item.songFrom}</p>
                      }
                    </div>
                  </div>
                  {
                    (item.songTitle2nd !== '' && item.songTitle2nd !== null && item.songTitle2nd !== 'null') &&
                    <div className="personal-program-many-contentrow-top">
                      <div className="personal-program-composition-textrow">
                        <p className="personal-program-composition">{item.songComposer2nd}</p>
                      </div>
                      <div className="personal-program-songname-box">
                        <p className="personal-program-songname">{item.songTitle2nd}</p>
                        {
                          (item.songSubTitle2nd !== '' && item.songSubTitle2nd !== null && item.songSubTitle2nd !== 'null') &&
                          <p className="personal-program-subsongname">{item.songSubTitle2nd}</p>
                        }
                        {
                          (item.songFrom2nd !== '' && item.songFrom2nd !== null) &&
                          <p className="personal-program-subsongname">{item.songFrom2nd}</p>
                        }
                      </div>
                    </div>
                  }
                  <div className="personal-program-many-contentrow-bottom">
                    <div className="personal-program-moreBtn"
                      onClick={()=>{
                        handleViewMore(item);
                      }}
                    >
                      <p>more</p>
                      <MdOutlineKeyboardDoubleArrowRight />
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
      <div className="personal-program-subtitle">2부</div>
      <ul className="personal-program-cover">
        {
          props.programData2nd.map((item:any, index:any)=>{

            return (
              <li className="personal-program-list" key={index}>
                <div className="personal-program-many-titlerow">
                  <div className="personal-program-many-titlerow-left">
                    <h3>{item.playerPart}</h3>
                    <h1>{item.playerName}</h1>
                  </div>
                  <div className="personal-program-many-titlerow-right">
                    <p>지도교수 {item.lessonTeacher}</p>
                    {
                      (item.accompanist !== '' && item.accompanist !== null && item.accompanist !== 'null') &&
                      <p>반주자 {item.accompanist}</p>
                    }
                  </div>
                </div>
                <div className="personal-program-many-contentrow">
                  <div className="personal-program-many-contentrow-top">
                    <div className="personal-program-composition-textrow">
                      <p className="personal-program-composition">{item.songComposer}</p>
                    </div>
                    <div className="personal-program-songname-box">
                      <p className="personal-program-songname">{item.songTitle}</p>
                      {
                        (item.songSubTitle !== '' && item.songSubTitle !== null) &&
                        <p className="personal-program-subsongname">{item.songSubTitle}</p>
                      }
                      {
                        (item.songFrom !== '' && item.songFrom !== null) &&
                        <p className="personal-program-subsongname">{item.songFrom}</p>
                      }
                    </div>
                  </div>
                  {
                    (item.songTitle2nd !== '' && item.songTitle2nd !== null && item.songTitle2nd !== 'null') &&
                    <div className="personal-program-many-contentrow-top">
                      <div className="personal-program-composition-textrow">
                        <p className="personal-program-composition">{item.songComposer2nd}</p>
                      </div>
                      <div className="personal-program-songname-box">
                        <p className="personal-program-songname">{item.songTitle2nd}</p>
                        {
                          (item.songSubTitle2nd !== '' && item.songSubTitle2nd !== null && item.songSubTitle2nd !== 'null') &&
                          <p className="personal-program-subsongname">{item.songSubTitle2nd}</p>
                        }
                        {
                          (item.songFrom2nd !== '' && item.songFrom2nd !== null) &&
                          <p className="personal-program-subsongname">{item.songFrom2nd}</p>
                        }
                      </div>
                    </div>
                  }
                  <div className="personal-program-many-contentrow-bottom">
                    <div className="personal-program-moreBtn"
                      onClick={()=>{
                        handleViewMore(item);
                      }}
                    >
                      <p>more</p>
                      <MdOutlineKeyboardDoubleArrowRight />
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}



