import { useEffect, useState } from 'react';
import './Pamphlet.scss';
import './PamphletDetail.scss';
import MainURL from '../../MainURL';
import Footer from '../../components/Footer';
import {  useNavigate } from 'react-router-dom';
import MenuTemplate from './MenuTemplate';
import axios from 'axios';
import { BsShare } from "react-icons/bs";
import { motion } from "framer-motion";
import TemplateNotice from './detailTemplates/TemplateNotice';
import TemplateSoloProgram from './detailTemplates/TemplateSoloProgram';
import TemplateSoloProfile from './detailTemplates/TemplateSoloProfile';
import TemplateGuestbookSolo from './detailTemplates/TemplateGuestbookSolo';

interface PostProps {
  id : number,
  location: string,
  title : string,
  titleEn1: string,
  titleEn2: string,
  date : string,
  time : string,
  place : string
  address : string,
  imageName : string,
  quiry: string,
  superViser: string,
  opener: string,
  supporter: string,
  ticket: string,
  ticketReserve: string,
  pamphletImages: string;
  placeNaver: string;
  placeKakao : string;
  placeHomepage : string;
}

interface ProgramProps {
  pamphletId : number,
  order : number,
  composition: string,
  songName: string,
  subSongName1: string,
  subSongName2: string,
  subSongName3: string,
  subSongName4: string,
  songFrom : string;
  notice:string;
  lyrics:string;
}

interface ProfileProps {
  pamphletID : number,
  playerName : string,
  part : string,
  imageName : string,
  career : string,
  isStyleWrite: string
}

interface CommentProps {
  id : number;
  post_id : number;
  content : string;
  userId : string;
  userName : string;
  date : string;
}

export default function PamphletDetail (props:any) {

  const url = new URL(window.location.href);
  const ID = url.searchParams.get("id");
  const currentNum = url.searchParams.get("currentNum");
  const currentSubNum = url.searchParams.get("currentSubNum");

  const [selectedDarkMode, setSelectedDarkMode] = useState(false);

  const darkMode1 = {backgroundColor: selectedDarkMode ? '#333': '#fff', color: selectedDarkMode ? '#fff':'#333'}
  const darkMode2 = {backgroundColor: selectedDarkMode ? '#333': '#fff', color: selectedDarkMode ? '#fff':'#333'}

  let navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentSelectTab, setCurrentSelectTab] = useState("info");
  
  const [postData, setPostData] = useState<PostProps>();
  const [programData, setProgramData] = useState<ProgramProps[]>([]);
  const [profileData, setProfileData] = useState<ProfileProps[]>([]);
  const [commentsList, setCommentsList] = useState<CommentProps[]>([]);
  const [pamphletImages, setPamphletImages] = useState<string[]>([]);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const resPamphlet = await axios.post(`${MainURL}/pamphlets/getdatapamphletpart`, {
      id : ID
    })
    if (resPamphlet.data) {
      const copy = {...resPamphlet.data[0]}
      setPostData(copy);
      const pamphletImagesCopy = copy.pamphletImages ? JSON.parse(copy.pamphletImages) : [];
      setPamphletImages(pamphletImagesCopy);
    } 
    const resProgram = await axios.post(`${MainURL}/pamphlets/getdataprogrampart`, {
      id : ID
    })
    if (resProgram.data) {
      const programData = [...resProgram.data];
      setProgramData(programData);
      setIsNoticeView(Array(programData.length).fill(false));
      setIsLyricsView(Array(programData.length).fill(false));
    }
    const resProfile = await axios.post(`${MainURL}/pamphlets/getdataprofilepart`, {
      id : ID
    })
    if (resProfile.data) {
      let copy : ProfileProps[] = resProfile.data;
      setProfileData(copy);
    }
    const resGuestbook = await axios.get(`${MainURL}/pamphlets/getpamphletcomments/${ID}`)
    if (resGuestbook.data) {
      let copy : CommentProps[] = resGuestbook.data;
      copy.reverse();
      setCommentsList(copy);
    }
  };



  useEffect(() => {
    fetchPosts();
  }, []);  

  const [isNoticeView, setIsNoticeView] = useState<boolean[]>([]);
  const [isLyricsView, setIsLyricsView] = useState<boolean[]>([]);
  

   const handleCopy = () => {
    navigator.clipboard.writeText(url.href).then(() => {
      alert('현재 링크 주소가 복사되었습니다.');
    }).catch((err) => {
      console.error('복사에 실패했습니다.', err);
    });
  };



  return  (
    <div className="pamphlet" style={darkMode1}>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={currentNum} currentSubNum={currentSubNum} navigate={navigate} />
        
        <div className="subpage__main">

          <div className="subpage__main__title" style={{color: selectedDarkMode ? '#fff' : '#333'}}>
            <div className='shareBtnbox'
              onClick={handleCopy}
              style={{backgroundColor: selectedDarkMode ? '#333': '#fff'}}
            >
              <BsShare style={{fontSize:'16px'}}/>
            </div>
          </div>

            <div className="subpage__main__content" style={{color:selectedDarkMode ? '#fff' : '#333'}}>
              <div className="main__content">

                <div className="switch-btn-box"
                  onClick={()=>{
                    setSelectedDarkMode(!selectedDarkMode);
                  }}
                >
                  <div className={`switch-toggle-container ${selectedDarkMode ? "switch-toggle--checked" : null}`}/>
                  <div className={`switch-toggle-circle ${selectedDarkMode ? "switch-toggle--checked" : null}`}/>
                  <div className="switch-btn-text" style={{color:selectedDarkMode ? '#fff' : '#333'}}>다크모드</div>
                </div>

                <div className='main-title-box'>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0 }}>
                   <h1>{postData?.titleEn1}</h1>
                  </motion.div>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.4 }}>
                    <h2>{postData?.titleEn2}</h2>
                  </motion.div>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
                    <h3>{postData?.title}</h3>
                  </motion.div>
                </div>

                <div className="selector__wrapper">
                  <div className={`selector__bar ${currentSelectTab === "info" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => {setCurrentSelectTab("info")}}
                  >연주회정보</div>
                  <div className={`selector__bar ${currentSelectTab === "program" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("program")}
                  >프로그램</div>
                  <div className={`selector__bar ${currentSelectTab === "profile" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("profile")}
                  >프로필</div>
                  <div className={`selector__bar ${currentSelectTab === "guestbook" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("guestbook")}
                  >방명록</div>
                 </div>

                { 
                  currentSelectTab === 'info' &&
                  <TemplateNotice selectedDarkMode={selectedDarkMode} postData={postData} pamphletImages={pamphletImages}/>
                }
                {
                  currentSelectTab === 'program' &&
                  <TemplateSoloProgram selectedDarkMode={selectedDarkMode} programData={programData}
                    isLyricsView={isLyricsView} isNoticeView={isNoticeView} setIsLyricsView={setIsLyricsView} setIsNoticeView={setIsNoticeView}
                  />
                }
                {
                  currentSelectTab === 'profile' &&
                  <TemplateSoloProfile selectedDarkMode={selectedDarkMode} profileData={profileData}/>
                }
                {
                  currentSelectTab === 'guestbook' &&
                  <TemplateGuestbookSolo darkMode2={darkMode2} commentsList={commentsList}
                    refresh={refresh} setRefresh={setRefresh} ID={ID}
                  />
                }
            </div>
          </div>

        </div>

      </div>
      <Footer/>
    </div>
  )
}



