import React, { useCallback, useEffect, useRef, useState } from 'react';
import '../Register.scss';
import './MainMany.scss';
import MainURL from '../../../MainURL';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuTemplate from '../MenuTemplate';
import { useDropzone } from 'react-dropzone'
import imageCompression from "browser-image-compression";
import { format } from 'date-fns';
import Loading from '../../../components/Loading';
import { CiCircleMinus } from 'react-icons/ci';
import axios from 'axios';
import { useRecoilValue } from 'recoil';
import { recoilLoginState, recoilUserData } from '../../../RecoilStore';
import { FaArrowLeftLong } from "react-icons/fa6";


export default function RegisterComplexProgram (props:any) {

  let navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState(2);
  const location = useLocation()
  const paramsData = location.state.data;
  const isAddOrRevise = location.state.isAddOrRevise;

  const isLogin = useRecoilValue(recoilLoginState);
  const userData = useRecoilValue(recoilUserData);
 
  const [title, setTitle] = useState(isAddOrRevise === 'revise' ? paramsData.title : "");
  const [playerName, setPlayerName] = useState(isAddOrRevise === 'revise' ? JSON.parse(paramsData.playerName) : [""]);
  const [subPlayers, setSubPlayers] = useState(isAddOrRevise === 'revise' ? JSON.parse(paramsData.subPlayers) : [""]);
  
  const [songNum, setSongNum] = useState(isAddOrRevise === 'revise' ? paramsData.songNum : '1');
  
  const [songComposer, setSongComposer] = useState(isAddOrRevise === 'revise' ? paramsData.songComposer : '');
  const [songTitle, setSongTitle] = useState(isAddOrRevise === 'revise' ? paramsData.songTitle : '');
  const [songSubTitle, setSongSubTitle] = useState(isAddOrRevise === 'revise' ? paramsData.songTitle : '');
  const [songComposer2nd, setSongComposer2nd] = useState(isAddOrRevise === 'revise' ? paramsData.songComposer2nd : null);
  const [songTitle2nd, setSongTitle2nd] = useState(isAddOrRevise === 'revise' ? paramsData.songTitle2nd : null);
  const [songSubTitle2nd, setSongSubTitle2nd] = useState(isAddOrRevise === 'revise' ? paramsData.songTitle : '');
  
  const [playOrderMain, setPlayOrderMain] = useState(isAddOrRevise === 'revise' ? paramsData.playOrderMain : '1부');  
  const [playOrder, setPlayOrder] = useState(isAddOrRevise === 'revise' ? paramsData.playOrder : '1');  
  const [password, setPassword] = useState('');

  const playOrderOption = [
    { value: '1', label: '1'},
    { value: '2', label: '2'},
    { value: '3', label: '3'},
    { value: '4', label: '4'},
    { value: '5', label: '5'},
    { value: '6', label: '6'},
    { value: '7', label: '7'},
    { value: '8', label: '8'},
    { value: '9', label: '9'},
    { value: '10', label: '10'},
    { value: '11', label: '11'},
    { value: '12', label: '12'},
    { value: '13', label: '13'},
    { value: '14', label: '14'},
    { value: '15', label: '15'}
  ];


   // 글쓰기 등록 함수 ----------------------------------------------
  const currentDate = new Date();
  const datecopy = format(currentDate, "yyyy-MM-dd");
  const registerPost = async () => {
    const getParams = {
      userAccount : userData.userAccount,
      pamphletId : paramsData.id,
      playOrderMain : playOrderMain,
      playOrder: playOrder,
      title: title,
      playerName: JSON.stringify(playerName),
      subPlayers: JSON.stringify(subPlayers),
      songNum : songNum,
      songComposer: songComposer,
      songTitle: songTitle,
      songSubTitle: songSubTitle,
      songComposer2nd: songComposer2nd,
      songTitle2nd: songTitle2nd,
      songSubTitle2nd: songSubTitle2nd,
      inputDate : datecopy,
      password: password
    }
    axios
      .post(`${MainURL}/register/registercomplexprogram`, getParams)
      .then((res) => {
        if (res.data) {
          alert('저장되었습니다.')
          navigate(-1);
        }
      })
      .catch(() => {
        console.log('실패함')
      })
  };

  // 수정 함수 ----------------------------------------------
  const revisePost = async () => {
    const getParams = {
      postId : paramsData.id,
      playOrderMain : playOrderMain,
      playOrder: playOrder,
      title: title,
      playerName: JSON.stringify(playerName),
      subPlayers: JSON.stringify(subPlayers),
      songNum : songNum,
      songComposer: songComposer,
      songTitle: songTitle,
      songSubTitle: songSubTitle,
      songComposer2nd: songComposer2nd,
      songTitle2nd: songTitle2nd,
      songSubTitle2nd: songSubTitle2nd,
      inputDate : datecopy,
    }
    axios
      .post(`${MainURL}/register/revisecomplexprogram`, getParams)
      .then((res) => {
        if (res.data) {
          alert('수정되었습니다.')
          navigate(-1);
        }
      })
      .catch(() => {
        console.log('실패함')
      })
  };

  	// 삭제 함수 ------------------------------------------------------------------------------------------------------------------------------------------
	const deletePost = async () => {
		const getParams = {
			postId : paramsData.id,
		}
		axios 
			.post(`${MainURL}/register/deletecomplex`, getParams)
			.then((res) => {
				if (res.data) {
          navigate(-1);
				}
			})
			.catch(() => {
				console.log('실패함')
			})
	};

	const handleDeleteAlert = () => {
		const costConfirmed = window.confirm(`정말 삭제하시겠습니까?`);
			if (costConfirmed) {
				deletePost();
		} else {
			return
		}
	};
  
  return  (
    <div className="main-register">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <div className="subpage__main__title">
              <h3>팜플렛등록</h3>
            </div>
          </div>

          <div className="subpage__main__content">

            <div className="main__content_registertitle">
              <p>{paramsData.title}</p>
            </div>
            <div className="main__content">
              
              <div className="info-inputbox">

                <div className="graduate-register-backBtnBox">
                  <div className="graduate-register-backBtn"
                    onClick={()=>{
                      navigate(-1);
                    }}
                  >
                    <FaArrowLeftLong size={20}/>
                    <p>뒤로가기</p>
                  </div>
                </div>

                <div className='graduate-register-inputCover'>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>타이틀</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={45}
                        onChange={(e)=>{setTitle(e.target.value)}} value={title} 
                      />
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>연주자</p>
                    </div>
                    <div className='graduate-register-input'>
                      {
                        playerName.map((item:any, index:any)=>{
                          return (
                            <div key={index} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'5px'}}>
                              <input type="text" style={{width:'80%'}}
                                maxLength={45} value={item} 
                                onChange={(e)=>{
                                  const copy = [...playerName]
                                  copy[index] = e.target.value;
                                  setPlayerName(copy);
                                }} 
                              />
                              <div className="plus-minus-btn"
                                style={{width:'8%'}}
                                onClick={()=>{
                                  const copy = [...playerName, ""]
                                  setPlayerName(copy);
                                }}
                              >
                                <p>+</p>
                              </div>
                              <div className="plus-minus-btn"
                                style={{width:'8%'}}
                                onClick={()=>{
                                  const copy = [...playerName]
                                  copy.splice(index, 1);
                                  setPlayerName(copy);
                                }}
                              >
                                <p>-</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>서브연주자<span style={{fontSize:'12px'}}>(선택)</span></p>
                    </div>
                    <div className='graduate-register-input'>
                      {
                        subPlayers.map((item:any, index:any)=>{
                          return (
                            <div key={index} style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'5px'}}>
                              <input type="text" style={{width:'80%'}}
                                maxLength={45} value={item} 
                                onChange={(e)=>{
                                  const copy = [...subPlayers]
                                  copy[index] = e.target.value;
                                  setSubPlayers(copy);
                                }} 
                              />
                             <div className="plus-minus-btn"
                               style={{width:'8%'}}
                                onClick={()=>{
                                  const copy = [...subPlayers, ""]
                                  setSubPlayers(copy);
                                }}
                              >
                                <p>+</p>
                              </div>
                              <div className="plus-minus-btn"
                                style={{width:'8%'}}
                                onClick={()=>{
                                  const copy = [...subPlayers]
                                  copy.splice(index, 1);
                                  setSubPlayers(copy);
                                }}
                              >
                                <p>-</p>
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>연주 곡수</p>
                    </div>
                    <div className='graduate-register-input'>
                      <div className="register-inputbox">
                        <div className='register-input'>
                          <select value={songNum} onChange={(e)=>{setSongNum(e.target.value)}} className="input">
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='graduate-register-inputCover'>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>작곡가1</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songComposer} 
                        onChange={(e)=>{
                          setSongComposer(e.target.value);
                        }} 
                      />
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>곡명1</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songTitle} 
                        onChange={(e)=>{
                          setSongTitle(e.target.value)
                        }} 
                      />
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>소제목1</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songSubTitle} 
                        onChange={(e)=>{
                          setSongSubTitle(e.target.value)
                        }} 
                      />
                    </div>
                  </div>
                </div>
                <div className='graduate-register-inputCover'>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>작곡가2</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songComposer2nd} 
                        onChange={(e)=>{
                          setSongComposer2nd(e.target.value);
                        }} 
                      />
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>곡명2</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songTitle2nd} 
                        onChange={(e)=>{
                          setSongTitle2nd(e.target.value);
                        }} 
                      />
                    </div>
                  </div>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>소제목2</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={100}
                        value={songSubTitle2nd} 
                        onChange={(e)=>{
                          setSongSubTitle2nd(e.target.value)
                        }} 
                      />
                    </div>
                  </div>
                </div>
                
              
                <div className='graduate-register-inputCover'>
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>연주순서</p>
                    </div>
                    <div className='graduate-register-input'>
                      <div className="register-inputbox">
                        {/* <div className='register-input'> */}
                        <div style={{display:'flex', alignItems:'center', justifyContent:'space-between', marginBottom:'5px'}}>
                          <select value={playOrderMain} onChange={(e)=>{setPlayOrderMain(e.target.value)}} className="input">
                            <option value='1부'>1부</option>
                            <option value='2부'>2부</option>
                          </select>
                          <select value={playOrder} onChange={(e)=>{setPlayOrder(e.target.value)}} className="input">
                            {playOrderOption.map((option:any, index:any) => (
                              <option key={index} value={option.value}>{option.label}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                {
                  isAddOrRevise === 'add' &&
                  <div className="graduate-register-inputbox">
                    <div className="graduate-register-name">
                      <p>임시비밀번호</p>
                    </div>
                    <div className='graduate-register-input'>
                      <input type="text" 
                        maxLength={10}
                        onChange={(e)=>{setPassword(e.target.value)}} value={password} 
                        placeholder={ '수정할때 필요한 비밀번호를 입력해주세요. (~10자)'}/>
                    </div>
                  </div>
                }
                </div>
                
                
              </div>
              
            </div>

            <div className="buttonbox">
              {
                isAddOrRevise === 'revise' &&
                <div className="button"
                  style={{backgroundColor:'#FF0000'}}
                  onClick={()=>{
                    handleDeleteAlert();
                  }}
                  >
                  <p>삭제하기</p>
                </div>
              }
              <div className="button"
                style={{backgroundColor:'#BDBDBD'}}
                onClick={()=>{
                  navigate(-1);
                }}
                >
                <p>취소하기</p>
              </div>
              <div className="button"
                onClick={()=>{
                  isAddOrRevise === 'revise'
                  ? revisePost()
                  : registerPost()
                }}
                >
                <p>{isAddOrRevise === 'revise'? '수정' : '입력'}하기</p>
              </div>
            </div>           

          </div>

        </div>

      </div>
      <Footer/>
    </div>
  )
}



