import React, { useEffect, useState } from 'react';
import '../Pamphlet.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import { DropdownBox } from '../../../components/DropdownBox';
import MenuTemplate from '../MenuTemplate';
import PamphletList from '../PamphletList';


export default function PamphletComplex(props:any) {

  let navigate = useNavigate();

  interface ListProps {
    id : number,
    isView: string
    pamphletId: string;
    type: string;
    template: string;
    sort: string;
    title: string;
    location: string;
    date: string;
    dateOrigin: string;
    time: string;
    place: string;
    address: string;
    superViser: string;
    supporter: string;
    ticket: string;
    ticketReserve: string;
    quiry: string;
    imageName: string;
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/pamphlets/getdatapamphlet`, {
      type : '연합연주회',
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);  

  
  interface PamphletGroup {
    type: string;
    pamphletList: ListProps[];
  }
  
  const pamphletData: PamphletGroup[] = list.reduce((acc: PamphletGroup[], curr: ListProps) => {
    if (!curr.isView) return acc;
    const type = curr.type;
    const existingGroup = acc.find(group => group.type === type);
    const list: ListProps = {
        id : curr.id,
        isView: curr.isView,
        pamphletId: curr.pamphletId,
        type: curr.type,
        template: curr.template,
        sort: curr.sort,
        title: curr.title,
        location: curr.location,
        date: curr.date,
        dateOrigin: curr.dateOrigin,
        time: curr.time,
        place: curr.place,
        address: curr.address,
        superViser: curr.superViser,
        supporter: curr.supporter,
        ticket: curr.ticket,
        ticketReserve: curr.ticketReserve,
        quiry: curr.quiry,
        imageName: curr.imageName
    };
    if (existingGroup) {
        existingGroup.pamphletList.push(list);
    } else {
        acc.push({
            type: type,
            pamphletList: [list]
        });
    }
    return acc;
  }, []);;

  // 글자 검색 ------------------------------------------------------
	const handleWordSearching = async () => {
    setList([]);
    if (searchWord.length < 2) {
      alert('2글자이상 입력해주세요')
    } else {
      const res = await axios.post(`${MainURL}/pamphlets/getdatapamphletsearch`, {
        type : '연합연주회',
        word: searchWord
      })
      if (res.data.data) {
        setIsResdataFalse(false);
        let copy: any = [...res.data.data];
        setList(copy);
        setListAllLength(res.data.count);
      } else {
        setListAllLength(0);
        setIsResdataFalse(true);
      }
    }
	};

 
  return (
    <div className="pamphlet">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={3} currentSubNum={1} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>연합연주회</h3>
          </div>
          
          <div className="subpage__main__search">
           
            <div className="subpage__main__search__box">
              <p className="buttons" style={{marginRight:'10px'}}>검색:</p>
              <input className="inputdefault width" type="text" placeholder='연주회명 검색'
                value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}} 
                onKeyDown={(e)=>{if (e.key === 'Enter') {handleWordSearching();}}}
                />
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={handleWordSearching}>
                  <p>검색</p>
                </div>
              </div>
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={()=>{
                    setSearchWord('');
                    fetchPosts();
                  }}>
                  <p>초기화</p>
                </div>
              </div>
            </div>
          </div>

          <PamphletList pamphletData={pamphletData} isResdataFalse={isResdataFalse} listAllLength={listAllLength} currentNum={3} currentSubNum={1}/>
        </div>

      </div>
      <Footer/>
    </div>
  )
}



