import { useEffect, useState } from 'react';
import './Pamphlet.scss';
import './PamphletDetail.scss';
import MainURL from '../../MainURL';
import Footer from '../../components/Footer';
import { useNavigate } from 'react-router-dom';
import MenuTemplate from './MenuTemplate';
import axios from 'axios';
import { BsShare } from "react-icons/bs";
import { motion } from "framer-motion";
import TemplateNotice from './detailTemplates/TemplateNotice';
import TemplateMessage from './detailTemplates/TemplateMessage';
import TemplateComplexProgram from './detailTemplates/TemplateComplexProgram';
import TemplateComplexDetail from './detailTemplates/TemplateComplexDetail';
import TemplateComplexProfile from './detailTemplates/TemplateComplexProfile';

interface PostProps {
  id : number,
  location: string,
  title : string,
  titleEn1: string,
  titleEn2: string,
  date : string,
  time : string,
  place : string
  address : string,
  imageName : string,
  quiry: string,
  superViser: string,
  opener: string,
  supporter: string,
  ticket: string,
  ticketReserve: string,
  pamphletImages: string;
  placeNaver: string;
  placeKakao : string;
  placeHomepage : string;
}

interface MessageinfoProps {
  id : number,
  title: string;
  name: string;
  ownerDean: string;
  image: string;
  inviteComment: string;
  faculty: string;
  homepage: string;
}

interface FacultyProps {
  facultySort: string;
  professors: {
    part: string;
    names: string;
    image: string;
  }
}

interface ProgramProps {
  id : string;
  userAccount : string;
  pamphletId : string;
  sort : string;
  playOrderMain: string;
  playOrder: string;
  title : string;
  playerName : string;
  subPlayers : string;
  playerID : string;
  songNum: string;
  songComposer : string;
  songTitle : string;
  songComposer2nd : string;
  songTitle2nd : string;
  inputDate : string;
  password : string;
}

interface ProfileProps {
  id : string;
  userAccount : string;
  pamphletId : string;
  sort : string;
  showOrder: string;
  playerName : string;
  playerPart : string;
  playerCareer : string;
  inputDate : string;
  postImage : string;  
  password : string;
}


export default function PamphletDetailComplex (props:any) {

  const url = new URL(window.location.href);
  const ID = url.searchParams.get("id");
  const currentNum = url.searchParams.get("currentNum");
  const currentSubNum = url.searchParams.get("currentSubNum");

  const [selectedDarkMode, setSelectedDarkMode] = useState(false);

  const darkMode1 = {backgroundColor: selectedDarkMode ? '#333': '#fff', color: selectedDarkMode ? '#fff':'#333'}
  const darkMode2 = {backgroundColor: selectedDarkMode ? '#333': '#fff', color: selectedDarkMode ? '#fff':'#333'}

  let navigate = useNavigate();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [currentSelectTab, setCurrentSelectTab] = useState("info");
  
  const [postData, setPostData] = useState<PostProps>();
  const [messageinfoData, setMessageinfoData] = useState<MessageinfoProps>();
  const [facultyData, setFacultyData] = useState<FacultyProps[]>([]);
  const [programData, setProgramData] = useState<ProgramProps[]>([]);
  const [profileData, setProfileData] = useState<ProfileProps>();
  const [profileDetailData, setProfileDetailData] = useState<ProfileProps>();
  const [pamphletImages, setPamphletImages] = useState<string[]>([]);
  
  
  // 게시글 가져오기
  const fetchPosts = async () => {
    const resPamphlet = await axios.post(`${MainURL}/pamphlets/getdatapamphletpart`, {
      id : ID
    })
    if (resPamphlet.data) {
      const copy = {...resPamphlet.data[0]}
      setPostData(copy);
      const pamphletImagesCopy = copy.pamphletImages ? JSON.parse(copy.pamphletImages) : [];
      setPamphletImages(pamphletImagesCopy);
    } 
    const resMessageinfo = await axios.post(`${MainURL}/pamphlets/getdatamessageinfopart`, {
      id : ID
    })
    if (resMessageinfo.data) {
      let copy : MessageinfoProps = resMessageinfo.data[0];
      setMessageinfoData(copy);
      const facultyCopy = (copy.faculty === '' || copy.faculty === null) ? null : JSON.parse(copy.faculty);
      setFacultyData(facultyCopy);
    }
    const resProgram = await axios.post(`${MainURL}/pamphlets/getdatacomplexprogram`, {
      id : ID
    })
    if (resProgram.data) {
      const copy = resProgram.data;
      setProgramData(copy);
    }
    const resProfile = await axios.post(`${MainURL}/pamphlets/getdatacomplexprofile`, {
      id : ID
    })
    if (resProfile.data) {
      const copy = resProfile.data;
      copy.sort((a: any, b: any) => {
        if (a.showOrder == null) return 1;
        if (b.showOrder == null) return -1;
        return a.showOrder - b.showOrder;
      });
      setProfileData(copy);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [refresh]);  


  const handleCopy = () => {
    navigator.clipboard.writeText(url.href).then(() => {
      alert('현재 링크 주소가 복사되었습니다.');
    }).catch((err) => {
      console.error('복사에 실패했습니다.', err);
    });
  };

  return  (
    <div className="pamphlet" style={darkMode1}>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={currentNum} currentSubNum={currentSubNum} navigate={navigate} />
        
        <div className="subpage__main">

          <div className="subpage__main__title" style={{color: selectedDarkMode ? '#fff' : '#333'}}>
            
            <div className='shareBtnbox'
              onClick={handleCopy}
              style={{backgroundColor: selectedDarkMode ? '#333': '#fff'}}
            >
              <BsShare style={{fontSize:'16px'}}/>
            </div>

          </div>

            <div className="subpage__main__content" style={{color:selectedDarkMode ? '#fff' : '#333'}}>
              <div className="main__content">

                <div className="switch-btn-box"
                  onClick={()=>{
                    setSelectedDarkMode(!selectedDarkMode);
                  }}
                >
                  <div className={`switch-toggle-container ${selectedDarkMode ? "switch-toggle--checked" : null}`}/>
                  <div className={`switch-toggle-circle ${selectedDarkMode ? "switch-toggle--checked" : null}`}/>
                  <div className="switch-btn-text" style={{color:selectedDarkMode ? '#fff' : '#333'}}>다크모드</div>
                </div>

                <div className='main-title-box'>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0 }}>
                   <h1>{postData?.titleEn1}</h1>
                  </motion.div>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 0.4 }}>
                    <h2>{postData?.titleEn2}</h2>
                  </motion.div>
                  <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1, delay: 1 }}>
                    <h3>{postData?.title}</h3>
                  </motion.div>
                </div>

                <div className="selector__wrapper">
                  <div className={`selector__bar ${currentSelectTab === "info" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => {setCurrentSelectTab("info")}}
                  >연주회정보</div>
                  <div className={`selector__bar ${currentSelectTab === "Message" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("Message")}
                  >소개</div>
                  <div className={`selector__bar ${currentSelectTab === "program" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("program")}
                  >프로그램</div>
                  <div className={`selector__bar ${currentSelectTab === "player" ? `${selectedDarkMode? "darkMode" : "on"}` : ""}`}
                    onClick={() => setCurrentSelectTab("player")}
                  >출연진</div>
                </div>

                { 
                  currentSelectTab === 'info' &&
                  <TemplateNotice selectedDarkMode={selectedDarkMode} postData={postData} pamphletImages={pamphletImages}/>
                }
                {
                  (currentSelectTab === 'Message' && messageinfoData !== undefined) &&
                  <TemplateMessage facultyData={facultyData} messageinfoData={messageinfoData}/>
                }
                {
                  currentSelectTab === 'program' &&
                  <TemplateComplexProgram selectedDarkMode={selectedDarkMode} programData={programData} setCurrentSelectTab={setCurrentSelectTab}
                    profileData={profileData} setProfileDetailData={setProfileDetailData}
                  />
                }
                {
                  (currentSelectTab === 'detail' && profileData !== undefined) &&
                  <TemplateComplexDetail selectedDarkMode={selectedDarkMode} profileData={profileData} setCurrentSelectTab={setCurrentSelectTab}
                    profileDetailData={profileDetailData} 
                  />
                }
                {
                  currentSelectTab === 'player' &&
                  <TemplateComplexProfile selectedDarkMode={selectedDarkMode} profileData={profileData}/>
                }
            </div>
          </div>

        </div>

      </div>
         
      
      <Footer/>
    </div>
  )
}



