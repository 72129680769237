import React, { useState } from 'react';
import { FiMinus, FiPlus } from "react-icons/fi";


export default function TemplateSoloProgram (props:any) {

  const noticeBtnSelection = (index : any) => {
    const selection = [...props.isNoticeView];
    if (selection[index] === false) {
      selection[index] = true
    } else if (selection[index] === true) {
      selection[index] = false
    }
    props.setIsNoticeView(selection);
  };

  const lyricsBtnSelection = (index : any) => {
    const selection = [...props.isLyricsView];
    if (selection[index] === false) {
      selection[index] = true
    } else if (selection[index] === true) {
      selection[index] = false
    }
    props.setIsLyricsView(selection);
  };

  return (
    <div className="programbox" style={{backgroundColor: props.selectedDarkMode ? '#333': '#fffef4'}}>
      <div className="program-title">&#183; Program &#183;</div>
      <ul className="program-cover">
        {
          props.programData.map((item:any, index:any)=>{

            return (
              <li className="program-list" key={index}>
               {
                  item.composition === 'InterMission' 
                  ?
                  null
                  :
                  <div className="program-composition-textrow">
                    <p className="program-composition" >{item.composition}</p>
                    <div className="program-notice-lyrics-btn-box">
                      {
                        item.notice &&
                        <div className="program-notice-lyrics-btn"
                          onClick={()=>{
                            noticeBtnSelection(index);
                          }}
                          style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
                        >
                          <p className='program-notice-lyrics-btn-text'>info</p>
                          { 
                            props.isNoticeView[index] 
                            ? <FiMinus className='program-notice-lyrics-btn-icon'/>
                            : <FiPlus className='program-notice-lyrics-btn-icon'/>
                          }
                        </div>
                      }
                      {
                        item.lyrics &&
                        <div className="program-notice-lyrics-btn"
                          onClick={()=>{
                            lyricsBtnSelection(index)
                          }}
                        >
                          <p className='program-notice-lyrics-btn-text'>가사</p>
                          {
                            props.isLyricsView[index]
                            ? <FiMinus className='program-notice-lyrics-btn-icon'/>
                            : <FiPlus className='program-notice-lyrics-btn-icon'/>
                          }
                        </div>
                      }
                    </div>
                  </div>
                }
                {
                  item.songName === 'InterMission' 
                  ?
                  <p className="InterMission">
                  - InterMission -
                  </p>
                  :
                  <div className="program-songname-box">
                    <p className="program-songname">{item.songName}</p>
                  </div>
                }
                {
                  (item.subSongName1 !== '' &&  item.subSongName1 !== null) &&
                  <p className="program-subsongname">{item.subSongName1}</p>
                }
                {
                  (item.subSongName2 !== '' &&  item.subSongName2 !== null) &&
                  <p className="program-subsongname">{item.subSongName2}</p>
                }
                {
                  (item.subSongName3 !== '' &&  item.subSongName3 !== null) &&
                  <p className="program-subsongname">{item.subSongName3}</p>
                }
                {
                  (item.subSongName4 !== '' &&  item.subSongName4 !== null) &&
                  <p className="program-subsongname">{item.subSongName4}</p>
                }
                <div className="program-songname-box">
                  <div className="program-notice-lyrics-box">
                    {
                      item.notice &&
                      <>
                        {
                          props.isNoticeView[index] && 
                          <div className="program-notice-Contentbox"
                            onClick={()=>{noticeBtnSelection(index)}}
                            style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
                          >{item.notice}</div>
                        }
                      </>
                    }
                    {
                      item.lyrics &&
                      <>
                        {
                          props.isLyricsView[index] && 
                          <div className="program-notice-Contentbox"
                            onClick={()=>{lyricsBtnSelection(index)}}
                            style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
                          >{item.lyrics}</div>
                        }
                      </>
                    }
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}



