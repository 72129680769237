import React, { useCallback, useEffect, useState } from 'react';
import './Admin.scss'
import axios from 'axios'
import MainURL from "../MainURL";
import { DropdownBox } from '../components/DropdownBox';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';


interface ListProps {
  id : number,
  isView: string
  pamphletId: string;
  type: string;
  sort: string;
  title: string;
  titleEn1: string;
  titleEn2: string;
  location: string;
  date: string;
  dateOrigin: string;
  time: string;
  place: string;
  address: string;
  superViser: string;
  supporter: string;
  ticket: string;
  ticketReserve: string;
  quiry: string;
  imageName: string;
}


interface ProfileProps {
  pamphletID : number,
  playerName : string,
  part : string,
  imageName : string,
  career : string,
  isStyleWrite: string
}

interface ProgramProps {
  pamphletId : number,
  order : number,
  composition: string,
  songName: string,
  subSongName: string[],
  notice:string;
  lyrics:string;
}

export default function Revise( props: any) {

  let navigate = useNavigate();

  const [list, setList] = useState<ListProps[]>([]);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/pamphlets/getdatapamphlet`, {
      type: 'all'
    })
    if (res.data.data) {
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
    } 
  };

  useEffect(() => {
    fetchPosts();
  }, []);  

   
  const [postId, setPostId] = useState('');
  const [pamphletTitle, setPamphletTitle] = useState('');
  const [pamphletTitleEn1, setPamphletTitleEn1] = useState('');
  const [pamphletTitleEn2, setPamphletTitleEn2] = useState('');
  const [profileData, setProfileData] = useState<ProfileProps[]>([]);
  const [selectProfileData, setSelectProfileData] = useState('');
  const [programData, setProgramData] = useState<ProgramProps[]>([]);
  const [selectProgramData, setSelectProgramData] = useState('');

  // 게시글 가져오기
  const fetchPostsdata = async (ID:string) => {
    const resProfile = await axios.post(`${MainURL}/pamphlets/getdataprofilepart`, {
      id : ID
    })
    if (resProfile.data) {
      let copy : ProfileProps[] = resProfile.data;
      setProfileData(copy);
    }
  };
  const fetchProgramData = async (ID:string) => {
    const resProgram = await axios.post(`${MainURL}/pamphlets/getdataprogrampart`, {
      id : ID
    })
    if (resProgram.data) {
      let copy : ProgramProps[] = resProgram.data;
      setProgramData(copy);
    }
  };

  // 팜플렛 수정 함수 ----------------------------------------------
  const registerPostPamphlet = async () => {
    axios 
      .post(`${MainURL}/admin/adminrevisepamphlet`, {
          postId: postId,
          title : pamphletTitle,
          titleEn1 : pamphletTitleEn1,
          titleEn2 : pamphletTitleEn2
      })
      .then((res) => {
          if (res.data) {
            alert('수정되었습니다.');
          }
      })
      .catch(() => {
        console.log('실패함')
      })
  };
 
   // 프로필 수정 함수 ----------------------------------------------
   const registerPostProfile = async () => {
     axios 
       .post(`${MainURL}/admin/adminreviseprofile`, {
          postId: postId,
          inputData: selectProfileData,
       })
       .then((res) => {
          if (res.data) {
            alert('수정되었습니다.');
            setSelectProfileData('');
          }
       })
       .catch(() => {
         console.log('실패함')
       })
   };

    
   // 프로그램 수정 함수 ----------------------------------------------
   const registerPostProgram = async () => {
    axios 
      .post(`${MainURL}/admin/adminreviseprogram`, {
         postId: postId,
         inputData: selectProgramData,
      })
      .then((res) => {
         if (res.data) {
           alert('수정되었습니다.');
           setSelectProgramData('');
         }
      })
      .catch(() => {
        console.log('실패함')
      })
  };
  
  
  return  (
    <div className="admin-register">

      <div className="inner">
        <div className="inputbox">
          <div className='name'>
            <p>연주회명</p>
          </div>
          <textarea  style={{minHeight:'100px'}}
            onChange={(e)=>{setPamphletTitle(e.target.value)}} value={pamphletTitle} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>연주회명En1</p>
          </div>
          <textarea  style={{minHeight:'100px'}}
            onChange={(e)=>{setPamphletTitleEn1(e.target.value)}} value={pamphletTitleEn1} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>연주회명En2</p>
          </div>
          <textarea  style={{minHeight:'100px'}}
            onChange={(e)=>{setPamphletTitleEn2(e.target.value)}} value={pamphletTitleEn2} />
        </div>

        
        <div className="buttonbox">
          <div className="button" onClick={()=>{navigate(-1)}}>
            <p>뒤로가기</p>
          </div>
          <div className="button" onClick={registerPostPamphlet}>
            <p>팜플렛 수정하기</p>
          </div>
        </div>

        <div className="inputbox" style={{}}>
          <div className='name'>
            <p>연주회 리스트</p>
          </div>
          <div className="facultyBox">
            { list.length > 0
              ?
              list.slice(0,10).map((item:any, index:any)=>{
                return (
                  <div className="faculty-input-row" key={index}>
                    <div style={{width:'50%'}}>{item.title}</div>
                    <div style={{width:'50%', display:'flex'}}>
                      <div style={{width:'50%'}}
                        onClick={()=>{
                          setPostId(item.pamphletId);
                          fetchPostsdata(item.pamphletId);
                          fetchProgramData(item.pamphletId);
                          setPamphletTitle(item.title);
                          setPamphletTitleEn1(item.titleEn1);
                          setPamphletTitleEn2(item.titleEn2);
                        }}
                      >
                        추가
                      </div>
                      <div style={{width:'50%'}}
                          onClick={()=>{
                            
                          }}
                      >
                        삭제
                      </div>
                    </div>
                    
                  </div>
                )
              })
              :
              <p>등록된 글이 없습니다.</p>
            }
          </div>
          
        </div>

        <div className="inputbox">
          <div className='name'>
            <p>연주자프로필</p>
          </div>
          <textarea style={{minHeight:'350px'}}
            onChange={(e)=>{setSelectProfileData(e.target.value)}} value={selectProfileData} />
        </div>

        <div className="inputbox" style={{}}>
          <div className='name'>
            <p>프로필 리스트</p>
          </div>
          <div className="facultyBox">
            { profileData.length > 0
              ?
              profileData.map((item:any, index:any)=>{
                return (
                  <div className="faculty-input-row" key={index}>
                    <div style={{width:'50%'}}>{item.playerName}</div>
                    <div style={{width:'50%', display:'flex'}}>
                      <div style={{width:'50%'}}
                        onClick={()=>{
                          setPostId(item.id);
                          setSelectProfileData(item.career)
                        }}
                      >
                        추가
                      </div>
                      <div style={{width:'50%'}}
                          onClick={()=>{
                            
                          }}
                      >
                        삭제
                      </div>
                    </div>
                    
                  </div>
                )
              })
              :
              <p>등록된 글이 없습니다.</p>
            }
          </div>
          
        </div>
        

        <div className="buttonbox">
          <div className="button" onClick={()=>{navigate(-1)}}>
            <p>뒤로가기</p>
          </div>
          <div className="button" onClick={registerPostProfile}>
            <p>프로필 수정하기</p>
          </div>
        </div>

        <div className="inputbox">
          <div className='name'>
            <p>프로그램 설명</p>
          </div>
          <textarea style={{minHeight:'350px'}}
            onChange={(e)=>{setSelectProgramData(e.target.value)}} value={selectProgramData} />
        </div>

        <div className="inputbox" style={{}}>
          <div className='name'>
            <p>프로그램 리스트</p>
          </div>
          <div className="facultyBox">
            { programData.length > 0
              ?
              programData.map((item:any, index:any)=>{
                return (
                  <div className="faculty-input-row" key={index}>
                    <div style={{width:'50%'}}>{item.songName}</div>
                    <div style={{width:'50%', display:'flex'}}>
                      <div style={{width:'50%'}}
                        onClick={()=>{
                          setPostId(item.id);
                          setSelectProgramData(item.notice)
                        }}
                      >
                        추가
                      </div>
                      <div style={{width:'50%'}}
                          onClick={()=>{
                            
                          }}
                      >
                        삭제
                      </div>
                    </div>
                    
                  </div>
                )
              })
              :
              <p>등록된 글이 없습니다.</p>
            }
          </div>
          
        </div>

        <div className="buttonbox">
          <div className="button" onClick={()=>{navigate(-1)}}>
            <p>뒤로가기</p>
          </div>
          <div className="button" onClick={registerPostProgram}>
            <p>프로그램 수정하기</p>
          </div>
        </div>
      </div>

      

    </div>
  );
}
