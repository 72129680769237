import React, { useState } from 'react';
import { FiMinus, FiPlus } from "react-icons/fi";
import MainURL from '../../../MainURL';

export default function TemplateSoloProfile (props:any) {

  return (
    <div className="careerbox">
      {
        props.profileData?.map((item:any, index:any)=>{
          const profileImageURL = item.imageName ? `${MainURL}/images/pamphlet_player/${item.imageName}` : '';
          const careerCopy = item.isStyleWrite === "true" ? item.career : JSON.parse(item.career);
          const formattedText = item.isStyleWrite === "true" && careerCopy.replace(/\r/g, '<br />');

          return (
            <div className="career-container" key={index}>
              
              <div className="career-playerImage">
                {
                  (item.imageName !== 'undefined' && item.imageName !== null) &&
                  <div className="career-image">
                    <img src={profileImageURL} alt='profileImage'
                      style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
                    />
                  </div>
                }
              </div>
              <div className="career-content">
                <ul className="career-namecover">
                  <p className="career-part">{item.part}.</p>
                  <p className="career-name">{item.playerName}</p>
                </ul>
                <ul className="career-listcover">
                  {
                    item.isStyleWrite === "true"
                    ?
                    <div className="career-writting">
                      <div
                        dangerouslySetInnerHTML={{ __html: formattedText }}
                      />
                    </div>
                    :
                    careerCopy.map((subItem:any, index:any)=>{
                      return(
                        <li className="career-list" key={index}>
                          {subItem}
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}



