import React from 'react';
import { IoIosArrowForward } from 'react-icons/io';
import { MdHome } from 'react-icons/md';
import MainURL from '../../../MainURL';


export default function TemplateMessage (props:any) {

  return (
    <div className="messagebox">
      <div className="message-container" >
    
        <div className="message-content">
          <p className="message-Title">{props.messageinfoData.title}</p>
          <p className="message-invite-Contentbox">{props.messageinfoData.inviteComment}</p>
          <ul className="message-textBox">
            <p className="message-name">{props.messageinfoData.name}</p>
            <p className="message-dean">{props.messageinfoData.ownerDean}</p>
          </ul>
          {
            (props.messageinfoData.image !== null && props.messageinfoData.image !== '' ) &&
            <div className="message-Images-cover">
              <img src={`${MainURL}/images/pamphlet_message/${props.messageinfoData.image}`} alt='postermain'
                  style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
              />
            </div>
          }

          {
            (props.messageinfoData.name2nd !== null && props.messageinfoData.name2nd !== '' &&
              props.messageinfoData.ownerDean2nd !== null && props.messageinfoData.ownerDean2nd !== '' 
            ) &&
            <>
            <p className="message-invite-Contentbox"
              style={{ borderTop: '1px solid #a97933', marginTop:'100px'}}
            >{props.messageinfoData.inviteComment2nd}</p>
            <ul className="message-textBox">
              <p className="message-name">{props.messageinfoData.name2nd}</p>
              <p className="message-dean">{props.messageinfoData.ownerDean2nd}</p>
            </ul>
            {
              (props.messageinfoData.image2nd !== null && props.messageinfoData.image2nd !== '' ) &&
              <div className="message-Images-cover">
                <img src={`${MainURL}/images/pamphlet_message/${props.messageinfoData.image2nd}`} alt='postermain'
                    style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
                />
              </div>
            }
            </>
          }
         
        </div>

        {
          (props.facultyData !== '' && props.facultyData !== null && props.facultyData !== undefined) &&
          <div className="message-content">
            <p className="message-Title">교수진</p>
            { 
              props.facultyData.map((item:any, index:any)=>{
                return (
                  <div key={index} className='message-faculty-Contentbox'>
                    <div className="message-faculty-sort">
                      <p className='facultysort'>{item.facultySort}</p> 
                    </div>
                    <div className="message-faculty-professor">
                    {
                      item.professors.map((subItem:any, subIndex:any)=>{
                     
                        return ( 
                          <div className="message-faculty-professor-box" key={subIndex}>
                            {
                              (subItem.image !== null && subItem.image !== '' && subItem.image !== undefined) &&
                              <div className="message-faculty-professor-images">
                                <img src={`${MainURL}/images/pamphlet_message/${subItem.image}`} alt='postermain'
                                    style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
                                />
                              </div>
                            }
                            <div className="message-faculty-professor-part">
                              <p>{subItem.part}</p>
                            </div>
                            <div className="message-faculty-professor-names">
                              <p className='professor-names-main'>{subItem.names}</p>
                              { item.facultySort !== '외래강사' &&
                                <p className='professor-names-sub'>교수</p> 
                              }
                            </div>
                          </div>  
                        )
                      })
                    }
                    </div>
                  </div>
                )
              })
            }
          </div>
        }
        

        <div className="message-content">
          <p className="message-Title">홈페이지</p>
          <div className="message-mapBtnBox">
            <a  className="message-mapBtn"
              href={`${props.messageinfoData.homepage}`} target='_blank'>
              <div className="message-mapBtnImg">
                <div className='message-icon'><MdHome size={30}/></div>
              </div>
              <p className='message-map-text'>홈페이지 바로가기</p>
              <IoIosArrowForward className='message-map-icon'/>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}



