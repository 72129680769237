import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import Apply from './pages/Apply';
import Register from './pages/Register';
import RegisterPassword from './pages/RegisterPassword';
import RegisterPesonalList from './applyMany/RegisterPesonalList';
import RegisterPersonal from './applyMany/RegisterPersonal';
import RegisterComplexList from './applyMany/RegisterComplexList';
import RegisterComplexProgram from './applyMany/RegisterComplexProgram';
import RegisterComplexProfile from './applyMany/RegisterComplexProfile';



export default function Store() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Apply/>}/>
      <Route path="/register" element={<Register/>}/>
      <Route path="/registerpassword" element={<RegisterPassword/>}/>
      <Route path="/registerlistpersonal" element={<RegisterPesonalList/>}/>
      <Route path="/registerpersonal" element={<RegisterPersonal/>}/>
      <Route path="/registerlistmany" element={<RegisterComplexList/>}/>
      <Route path="/registermanyprogram" element={<RegisterComplexProgram/>}/>
      <Route path="/registermanyprofile" element={<RegisterComplexProfile/>}/>
    </Routes>
  </div>
  )
}
