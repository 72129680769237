import React, { useState } from 'react';
import kakaologo from "../../../images/login/kakao.png"
import naverlogo from "../../../images/login/naver.png"
import { MdHome } from "react-icons/md";
import MainURL from '../../../MainURL';
import { IoPeopleOutline } from "react-icons/io5";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoMdTime, IoIosArrowForward } from "react-icons/io";
import { MdOutlineHomeWork, MdOutlineAdsClick, MdOutlineHandshake } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { LuTicket } from "react-icons/lu";
import { MdKeyboardDoubleArrowDown, MdKeyboardDoubleArrowUp } from "react-icons/md";

export default function TemplateNotice(props:any) {

  const [isShowImages, setIsShowImages] = useState<boolean>(false);
  

  console.log(props.pamphletImages);
  return (
    <div className='noticebox'>
      <div className='noticebox-sub'>
        <ul className="noticebox-sub-info">
          <li className="notice-list">
            <div className='notice-title'>
              <FaRegCalendarAlt className='notice-title-icon'/>
              <p className='notice-title-text'>날짜</p>
            </div>
            <p className='notice-bar'></p>
            <p className='notice-content notice-content-check'>{props.postData?.date}</p>
          </li>
          <li className="notice-list">
            <div className='notice-title'>
              <IoMdTime className='notice-title-icon'/>
              <p className='notice-title-text'>시간</p>
            </div>
            <p className='notice-bar'></p>
            <p className='notice-content notice-content-check'>{props.postData?.time}</p>
          </li>
          <li className="notice-list">
            <div className='notice-title'>
              <MdOutlineHomeWork className='notice-title-icon'/>
              <p className='notice-title-text'>장소</p>
            </div>
            <p className='notice-bar'></p>
            <p className='notice-content notice-content-check'>{props.postData?.place}</p>
          </li>
          <li className="notice-list">
            <div className='notice-title'>
              <MdOutlineHandshake className='notice-title-icon'/>
              <p className='notice-title-text'>주관주최</p>
            </div>
            <p className='notice-bar'></p>
            <p className='notice-content'>{props.postData?.superViser}</p>
          </li>
          {
            (props.postData?.supporter !== '' && props.postData?.supporter !== null) &&
            <li className="notice-list">
              <div className='notice-title'>
                <IoPeopleOutline className='notice-title-icon'/>
                <p className='notice-title-text'>후원</p>
              </div>
              <p className='notice-bar'></p>
              <p className='notice-content'>{props.postData?.supporter}</p>
            </li>
          }
          {
            (props.postData?.ticket !== '' && props.postData?.ticket !== null) &&
            <li className="notice-list">
              <div className='notice-title'>
                <LuTicket className='notice-title-icon'/>
                <p className='notice-title-text'>티켓</p>
              </div>
              <p className='notice-bar'></p>
              <p className='notice-content'>{props.postData?.ticket}</p>
            </li>
          }
          {
            (props.postData?.ticketReserve !== '' && props.postData?.ticketReserve !== null) &&
            <li className="notice-list">
              <div className='notice-title'>
                <MdOutlineAdsClick  className='notice-title-icon'/>
                <p className='notice-title-text'>티켓예매</p>
              </div>
              <p className='notice-bar'></p>
              <p className='notice-content'>{props.postData?.ticketReserve}</p>
            </li>
          }
          <li className="notice-list">
            <div className='notice-title'>
              <FiPhone className='notice-title-icon'/>
              <p className='notice-title-text'>문의</p>
            </div>
            <p className='notice-bar'></p>
            <p className='notice-content'>{props.postData?.quiry}</p>
          </li>
        </ul>
        <div className="noticebox-sub-image">
          <div className="notice-image">
            <img src={`${MainURL}/images/pamphlet_default/${props.postData?.imageName}`} alt='postermain'
              style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
            />
          </div>
        </div>
      </div>
      
      <div className='noticebox-mapBox'>
        
        <div className='noticebox-maptitle'>
          
          <div className="noticebox-maptitle-left">
            <div className="noticebox-maptitle-divider for-pc"></div>  
            <p >오시는길</p>
          </div>
          <div className="noticebox-maptitle-right">
            <div className="noticebox-maptitle-divider"></div>  
            <div className="noticebox-maptitle-right-text">
              <div className='noticebox-maptitle-right-text-title' >{props.postData?.place}</div>
              <div className="noticebox-maptitle-divider-vertical for-pc"></div>  
              <div className='noticebox-maptitle-right-text-sub'>{props.postData?.address}</div>
            </div>
          </div>
        </div>
        <div className="noticebox-mapBtnBox">
          <a className="noticebox-mapBtn"
            href={`${props.postData?.placeNaver}`} target='_blank'>
            <div className="noticebox-mapBtnImg">
              <img src={naverlogo}/>
            </div>
            <p className='noticebox-map-text'>네이버 지도</p>
            <IoIosArrowForward className='noticebox-map-icon'/>
          </a>
          <a  className="noticebox-mapBtn"
            href={`${props.postData?.placeKakao}`} target='_blank'>
            <div className="noticebox-mapBtnImg">
              <img src={kakaologo}/>
            </div>
            <p className='noticebox-map-text'>카카오 지도</p>
            <IoIosArrowForward className='noticebox-map-icon'/>
          </a>
          <a  className="noticebox-mapBtn"
            href={`${props.postData?.placeHomepage}`} target='_blank'>
            <div className="noticebox-mapBtnImg">
              <div className='noticebox-icon'><MdHome size={30}/></div>
            </div>
            <p className='noticebox-map-text'>홈페이지 정보</p>
            <IoIosArrowForward className='noticebox-map-icon'/>
          </a>
        </div>
      </div>

      {
        isShowImages &&
        <div className="noticebox-pamphletImages-cover">
        {
          props.pamphletImages?.length > 0 &&
          props.pamphletImages?.map((item:any, index:any)=>{
            return (
              <img key={index}
                src={`${MainURL}/images/pamphlet_originImages/${item}`} alt='postermain'
                style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
              />
            )
          })
        }
        </div>
      }

      {
        props.pamphletImages?.length > 0 &&
        <div className="noticebox-pamphletImages-showBtn-cover">
          <div className="noticebox-pamphletImages-showBtn"
            onClick={()=>{
              setIsShowImages(!isShowImages)
            }}
          >
            <p>팜플렛 이미지 {isShowImages ? '닫기' : '전체 보기'}</p>
            {
              isShowImages
              ? <MdKeyboardDoubleArrowUp size={20}/>
              : <MdKeyboardDoubleArrowDown size={20}/>
            }
          </div>
        </div>
      }


    </div>
  )
}



