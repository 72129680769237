import React from 'react';
import { Routes, Route } from 'react-router-dom';

import MusicAll from './pamphlets/MusicAll';
import PamphletSolo from './pamphlets/PamphletSolo';
import PamphletGraduate from './pamphlets/PamphletGraduate';
import PamphletComplex from './pamphlets/PamphletComplex';

import PamphletDetail from './PamphletDetail';
import PamphletDetailPersonal from './PamphletDetailPersonal';
import PamphletDetailComplex from './PamphletDetailComplex';



export default function MainPlamphlet() {

  return (
    <div>
      <Routes>
        {/* <Route path="/" element={<MusicAll/>}/> */}

        <Route path="/" element={<PamphletSolo/>}/>
        <Route path="/graduate" element={<PamphletGraduate/>}/>
        <Route path="/complex" element={<PamphletComplex/>}/>

        <Route path="/pamphletdetail" element={<PamphletDetail/>}/>
        <Route path="/pamphletdetailpersonal" element={<PamphletDetailPersonal/>}/>
        <Route path="/pamphletdetailcomplex" element={<PamphletDetailComplex/>}/>
        

      </Routes>
    </div>
  );
}
