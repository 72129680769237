import React, { useEffect } from 'react';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';


export default function TemplateComplexProgram (props:any) {

  const handleViewMore = (itemCopy:any) => {
    const playerID_copy = JSON.parse(itemCopy.playerID);
    const profileDataCopy = props.profileData;
    const filteredProfiles = profileDataCopy.filter((item:any) => 
        playerID_copy.includes(String(item.id))
    );
    console.log(filteredProfiles);
    props.setProfileDetailData(filteredProfiles);
    props.setCurrentSelectTab("detail")
    window.scrollTo(0, 450);
  };

 
  return (
    <div className="complex-programbox" style={{backgroundColor: props.selectedDarkMode ? '#333': '#fffef4'}}>
      <div className="complex-program-title">&#183; Program &#183;</div>
      <ul className="complex-program-cover">
        {
          props.programData.map((item:any, index:any)=>{

            const playerNameCopy = item.playerName ? JSON.parse(item.playerName) : '';
            const subPlayersCopy = JSON.parse(item.subPlayers)

            return (
              <li className="complex-program-list" key={index}>
                <div className="complex-program-many-titlerow">
                  <div className="complex-program-many-titlerow-left">
                    <h1>{item.title}</h1>
                  </div>
                  <div className="complex-program-many-titlerow-right">
                    { playerNameCopy.map((name:any, index:any)=>(
                      <>
                      <p key={index}>{name}</p>
                      {
                        index+1 !== playerNameCopy.length &&
                        <p className='complex-program-many-titlerow-bar'>/</p>
                      }
                      </>
                    )) }
                  </div>
                </div>
                <div className="complex-program-many-subPlayers-row">
                { 
                  ( subPlayersCopy.length > 0 && subPlayersCopy[0] !== '') &&
                  subPlayersCopy.map((item:any, index:any)=>(
                    <h3 key={index}>{item}</h3>
                  ))
                }
                </div>
                <div className="complex-program-many-contentrow">
                  <div className="complex-program-many-contentrow-top">
                    <div className="complex-program-composition-textrow">
                      {
                        (item.songComposer !== '' && item.songComposer !== null && item.songComposer !== 'null') &&
                        <p className="complex-program-composition">{item.songComposer}</p>
                      }
                    </div>
                    <div className="complex-program-songname-box">
                      <p className="complex-program-songname">{item.songTitle}</p>
                      {
                        (item.songSubTitle !== '' && item.songSubTitle !== null && item.songSubTitle !== 'null') &&
                        <p className="complex-program-subsongname">{item.songSubTitle}</p>
                      }
                    </div>
                  </div>
                  { (item.songTitle2nd !== '' && item.songTitle2nd !== null && item.songTitle2nd !== 'null') &&
                    <div className="complex-program-many-contentrow-top">
                      <div className="complex-program-composition-textrow">
                        {
                          (item.songComposer2nd !== '' && item.songComposer2nd !== null && item.songComposer2nd !== 'null') &&
                          <p className="complex-program-composition">{item.songComposer2nd}</p>
                        }
                      </div>
                      <div className="complex-program-songname-box">
                        <p className="complex-program-songname">{item.songTitle2nd}</p>
                        {
                          (item.songSubTitle2nd !== '' && item.songSubTitle2nd !== null && item.songSubTitle2nd !== 'null') &&
                          <p className="complex-program-subsongname">{item.songSubTitle2nd}</p>
                        }
                      </div>
                    </div>
                  }
                  
                  <div className="complex-program-many-contentrow-bottom">
                    <div className="complex-program-moreBtn"
                      onClick={()=>{
                        handleViewMore(item);
                      }}
                    >
                      <p>more</p>
                      <MdOutlineKeyboardDoubleArrowRight />
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}



