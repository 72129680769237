
import React, { useState } from 'react';
import DateFormmating from '../../../components/DateFormmating';
import { format } from 'date-fns';
import axios from 'axios';
import MainURL from '../../../MainURL';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';


export default function TemplateGuestbookMany (props:any) {

  // 댓글 ----------------------------------------------
  const [toUser, setToUser] = useState('');
  const [inputUser, setInputUser] = useState('');
  const [inputComments, setInputComments] = useState('');
  const currentDate = new Date();
  const date = format(currentDate, 'yyyy-MM-dd');
  const registerComment = async () => {
    axios 
      .post(`${MainURL}/pamphlets/pamphletcommentsinput`, {
        postId : props.ID,
        inputUser: inputUser,
        toUser : toUser,
        commentText : inputComments,
        date : date,
      })
      .then((res) => {
        if (res.data) {
          alert('입력되었습니다.');
          setInputUser('')
          setInputComments('');
          props.setRefresh(!props.refresh);
        }
      })
      .catch(() => {
        console.log('실패함')
      })
  };
  
  return (
    <div className="commentMainBox">
      {
        <>
          <div className="commentPostBox">
            <div className='commentTitle'>
              <p>글쓴이</p>
              <h5 style={{fontSize:'12px'}}>* 최대 45자</h5>
            </div>
            <input
              style={props.darkMode2}
              className="commentinput"
              value={inputUser}
              maxLength={45}
              onChange={(e)=>{setInputUser(e.target.value)}}
            />
          </div>
          <div className="commentPostBox">
            <div className='commentTitle'>
              <p>받는이</p>
            </div>
            {
              props.playerList.length > 0  
              ?
              <select value={toUser} onChange={(e)=>{setToUser(e.target.value)}} className="commentselect"
                style={props.darkMode2}
              >
                { props.playerList.map((option:any, index:any) => (
                  <option key={index} value={option.value}>{option.label}</option>
                ))}
              </select>
              :
              <input
                style={props.darkMode2}
                className="commentinput"
                value={toUser}
                maxLength={45}
                onChange={(e)=>{setToUser(e.target.value)}}
              />
            }
          </div>
          <div className="commentPostBox">
            <div className='commentTitle'>
              <p>내용</p>
              <h5 style={{fontSize:'12px'}}>* 최대 200자</h5>
            </div>
            <textarea 
              style={props.darkMode2}
              className="commenttextarea"
              value={inputComments}
              placeholder='여기에 방명록을 적어주세요 :)'
              maxLength={200}
              onChange={(e)=>{setInputComments(e.target.value)}}
            />
          </div>

          <div className="buttonbox">
            <div className="button"
            onClick={()=>{
              registerComment();
            }}
            >
              <p>입력하기</p>
            </div>
          </div>
        </>
      }
      { props.commentsList.length > 0 
        ?
        props.commentsList.map((item:any, index:any)=>{
          return (
            <div className="commentsbox" key={index} style={props.darkMode2}>
              <div className="namebox">
                <div className='namebox-row'>
                  <h3>{item.inputUser}</h3>
                  <MdOutlineKeyboardDoubleArrowRight className='namebox-icon'/>
                  <h3>{item.toUser}</h3>
                </div>
                <p style={{marginLeft:'20px'}}>{DateFormmating(item.date)}</p>
              </div>
              <div className="textbox">
                <p>{item.comment}</p>
              </div>
            </div>
          )
        })
        :
        <div className="commentsbox">
          <p style={{fontSize:'16px'}}>입력된 글이 없습니다.</p>
        </div>
      }
    </div>  
  )
}



