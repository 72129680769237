import React, { useState } from 'react';
import { FiMinus, FiPlus } from "react-icons/fi";
import MainURL from '../../../MainURL';

export default function TemplateComplexProfile(props:any) {


  
  return (
    <div className="complex-profilebox">
      {
        props.profileData?.map((item:any, index:any)=>{
          const profileImageURL = item.postImage ? `${MainURL}/images/pamphlet_complex/${item.postImage}` : '';
          const formattedText = item.playerCareer.replace(/\r/g, '<br />');

          return (
            <div className="complex-profile-container" key={index}>
              <div className="complex-profile-content">
                <ul className="complex-profile-namecover">
                  <p className="complex-profile-part">{item.playerPart}.</p>
                  <p className="complex-profile-name" style={{}}>{item.playerName}</p>
                </ul>
                <ul className="complex-profile-listcover forpc">
                  <div className="complex-profile-writting">
                    <div
                      dangerouslySetInnerHTML={{ __html: formattedText }}
                    />
                  </div>
                </ul>
              </div>
              <div className="complex-profile-playerImage">
                {
                  (item.postImage !== 'undefined' && item.postImage !== null && item.postImage !== 'undefined') &&
                  <div className="complex-profile-image">
                    <img src={profileImageURL} alt='profileImage'
                      style={{opacity: props.selectedDarkMode ? '0.5': '1'}}
                    />
                  </div>
                }
              </div>
              <div className="complex-profile-content formobile">
                <ul className="complex-profile-listcover formobile">
                  <div className="complex-profile-writting">
                    <div
                      dangerouslySetInnerHTML={{ __html: formattedText }}
                    />
                  </div>
                </ul>
              </div>
            </div>
          )
        })
      }
    </div>
  )
}



