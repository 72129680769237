import React from 'react';
import MainURL from '../../../MainURL';

export default function TemplatePersonalProgramProfileDetail (props:any) {

  return (
    <div className="personal-careerbox">
      <div className="personal-career-container" >
        {
          (JSON.parse(props.profileData.postImage).length > 0 ) &&
          <div className="personal-career-playerImage">
            <div className="personal-career-image">
              <img src={`${MainURL}/images/pamphlet_personal/${JSON.parse(props.profileData.postImage)}`}
                alt='profileImage' style={{opacity: props.selectedDarkMode ? '0.5': '1'}}/>
            </div>
          </div>
        }
        <div className="personal-career-content">
          <ul className="personal-career-namecover">
            <p className="personal-career-part">{props.profileData.playerPart}.</p>
            <p className="personal-career-name">{props.profileData.playerName}</p>
          </ul>
          <ul className="personal-career-listcover">
            <div className="personal-career-writting">
              {props.profileData.playerCarrer}
            </div>
          </ul>
        </div>
      </div>
      <div className="many-songs-container">
        <p className="many-songs-composition">{props.profileData.songComposer}</p>
        <p className="many-songs-songname">{props.profileData.songTitle}</p>
        {
          (props.profileData.songSubTitle !== '' && props.profileData.songSubTitle !== null) &&
          <p className="many-songs-subsongname">{props.profileData.songSubTitle}</p>
        }
        {
          (props.profileData.songFrom !== '' && props.profileData.songFrom !== null) &&
          <p className="many-songs-subsongname">{props.profileData.songFrom}</p>
        }
        <div className="many-songs-notice-lyrics-box">
          <p className="many-songs-notice-Contentbox"
            style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
          >
          <div
            dangerouslySetInnerHTML={{ __html: props.profileData.songNotice.replace(/\r/g, '<br />') }}
          />
          </p>
          {
            (props.profileData.songLyrics !== '' && props.profileData.songLyrics !== null && props.profileData.songLyrics !== 'null') &&   
            <p className="many-songs-notice-Contentbox"
              style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
            >{props.profileData.songLyrics}</p>
          }
        </div>
      </div>
      {
        (props.profileData.songTitle2nd !== '' && props.profileData.songTitle2nd !== null && props.profileData.songTitle2nd !== 'null') &&
        <div className="many-songs-container" style={{marginTop:'20px'}}>
          <p className="many-songs-composition">{props.profileData.songComposer2nd}</p>
          <p className="many-songs-songname">{props.profileData.songTitle2nd}</p>
          {
            (props.profileData.songSubTitle2nd !== '' && props.profileData.songSubTitle2nd !== null && props.profileData.songSubTitle2nd !== 'null') &&
            <p className="many-songs-subsongname">{props.profileData.songSubTitle2nd}</p>
          }
          {
            (props.profileData.songFrom2nd !== '' && props.profileData.songFrom2nd !== null) &&
            <p className="many-songs-subsongname">{props.profileData.songFrom2nd}</p>
          }
          <div className="many-songs-notice-lyrics-box">
            <p className="many-songs-notice-Contentbox"
              style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
            >
              {
                (props.profileData.songNotice2nd !== '' && props.profileData.songNotice2nd !== null && props.profileData.songNotice2nd !== 'null') &&
                <div
                  dangerouslySetInnerHTML={{ __html: props.profileData.songNotice2nd.replace(/\r/g, '<br />') }}
                />
              }
            </p>
            {
              (props.profileData.songLyrics2nd !== '' && props.profileData.songLyrics2nd !== null && props.profileData.songLyrics2nd !== 'null') &&
              <p className="many-songs-notice-Contentbox"
                style={{backgroundColor: props.selectedDarkMode ? '#333': '#f6f5e9'}}
              >{props.profileData.songLyrics2nd}</p>
            }
          </div>
        </div>
      }
      

      <div className="many-buttonbox">
        <div className="many-button"
        onClick={()=>{
          props.setCurrentSelectTab("program")
        }}
        >
          <p>목록보기</p>
        </div>
      </div>

    </div>
  )
}



