import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist({
  key: 'artsbook', 
  storage: sessionStorage,
});


export const recoilLoginState = atom({
  key: "loginState",
  default: false,
  effects_UNSTABLE: [persistAtom]
});

export const recoilUserData = atom({
  key: "userData",
  default: {
    userAccount : '',
    userNickName : '',
    userSort: '',
    userDetail : '',
    grade: ''
  },
  effects_UNSTABLE: [persistAtom]
});


export const recoilKaKaoLoginData = atom({
  key: "kakaoLoginData",
  default: {
    APIKEY : '79d97a5935ea5e728ca2419be3468098',
    REDIRECT_URI_Auth : 'https://artsbook.co.kr/loginsns'
    // REDIRECT_URI_Auth : 'http://localhost:3000/loginsns'
  },
});


export const recoilNaverLoginData = atom({
  key: "naverLoginData",
  default: {
    CLIENTID : 'rTNXa8pFQ1b6E5jV2B6t',
    SECRET : 'f7tecQTacF',
    REDIRECT_URI_Auth : 'https://artsbook.co.kr/loginsns'
    // REDIRECT_URI_Auth : 'http://localhost:3000/loginsns'
  },
});
